import { FormApiContext } from '../utils';
import { OnError, ServiceInfo } from '../../types';
import { BookingPolicy } from '../../../types/ambassador/bookings/ambassador-services-catalog';

export type GetStrictestBookingPolicyArgs = {
  serviceList: ServiceInfo[];
  onError: OnError;
};

export const getStrictestBookingPolicy = async ({
  wixSdkAdapter,
  serviceList,
}: GetStrictestBookingPolicyArgs & FormApiContext): Promise<
  Maybe<BookingPolicy>
> => {
  if (wixSdkAdapter.isEditorMode()) {
    return;
  }

  const policiesIds = serviceList
    .map((service) => service.service?.service?.policy?.id)
    .filter((id): id is string => !!id);

  if (areAllValuesIdentical(policiesIds)) {
    return serviceList[0].service?.service?.policy;
  }

  // TODO: remove this and restore the commented section when the new API is ready
  return;

  // const { data, error } = await withErrorBoundary(
  //   {
  //     fn: async () => {
  //       const policy = await httpClient.request(
  //         getStrictestBookingPolicyAPI({
  //           bookingPolicyIds: policiesIds,
  //         }),
  //       );
  //       return policy.data;
  //     },
  //     mapError: (e) => ({
  //       error: ServerErrorType.FAILED_TO_FETCH_STRICTEST_BOOKING_POLICY,
  //       shouldReport: true,
  //     }),
  //     fallback: undefined,
  //   },
  //   reportError,
  // );

  // if (error) {
  //   onError(error);
  // }

  // return data;
};

const areAllValuesIdentical = (arr: string[]) => {
  if (arr.length === 0) {
    // Empty array is considered to have identical values
    return true;
  }

  const firstValue = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== firstValue) {
      return false; // Found a different value
    }
  }

  return true; // All values are identical
};
